<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                v-model="selectedDevice"
                class="form-control form-control-solid form-control--company-select"
                placeholder="Select device"
                :options="devices"
                :reduce="(device) => device['logger']"
                label="name"
              >
                <template #option="{ name, location, tags, logger }">
                  <span class="mr-3">{{ name }}</span>
                  <span class="label label-secondary label-inline mr-3">{{
                    location.title
                  }}</span>
                  <span
                    v-if="tags[0]"
                    class="label label-primary label-inline mr-3"
                    >{{ tags[0]['name'] }}</span
                  >
                  <span v-if="logger" class="mr-3"
                    ><strong>imei:</strong> {{ logger.imei }}</span
                  >
                  <span v-if="logger" class="mr-3"
                    ><strong>msisdn:</strong> +{{ logger.msisdnNumber }}</span
                  >
                </template>
              </vSelect>
            </div>
            <button
              v-if="selectedDevice"
              class="btn btn-primary btn-block font-weight-bold mt-4"
              @click="send"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  name: 'DevicesReset',
  components: {
    vSelect,
  },
  data() {
    return {
      devices: [],
      isDevicesLoaded: false,
      selectedDevice: null,
      isSubmitted: false,
      isSuccess: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Reset', route: '' }])

    ApiService.get(
      'devices',
      `?company=${this.currentUserCompany.replace(
        '/api/v1/companies/',
        ''
      )}&pagination=false`
    ).then(({ data }) => {
      this.devices = [...data['hydra:member']]
      this.isDevicesLoaded = true
    })
  },
  methods: {
    send() {
      this.isSubmitted = true
      axios
        .post(
          `${process.env.VUE_APP_ROUTE_API_URL}/send-reset-sms/${this.selectedDevice.msisdnNumber}`
        )
        .then(({ status }) => {
          if (status === 200) {
            this.isSuccess = true

            this.$notify({
              group: 'notification',
              type: 'success',
              title: 'SMS sent successfully',
            })
          } else {
            this.$notify({
              group: 'notification',
              type: 'error',
              title: 'Error',
            })
          }
        })
        .catch(() => {
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Error',
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--company-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
